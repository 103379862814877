<template>
  <a-card :bordered="false" class="card-area">

    <a-descriptions title="经销商设备管理" bordered size="small" :column="{sm:2}">
      <a-descriptions-item label="经销商">{{vendor.name}}</a-descriptions-item>
      <a-descriptions-item label="账号">{{vendor.account}}</a-descriptions-item>
      <a-descriptions-item label="备注">{{vendor.remark}}</a-descriptions-item>
      <a-descriptions-item label="创建时间">{{vendor.createdAt}}</a-descriptions-item>
      <a-descriptions-item label="产品">{{product.name}}</a-descriptions-item>
      <a-descriptions-item label="设备数量">{{product.deviceCount}}</a-descriptions-item>

    </a-descriptions>

    <common-table
      ref="table"
      path="device"
      :query-params="queryParams"
      :columns="columns">

      <template slot="search">
        <a-button v-if="product.allDevice" type="primary" ghost :disabled="true">已授权该产品的所有设备</a-button>
        <a-button v-else type="primary" ghost @click="add">新增设备授权</a-button>
      </template>

      <template slot="status" slot-scope="{record}">
        <a-tag v-if="record.status==1" color="green">在线</a-tag>
        <a-tag v-else color="cyan">离线</a-tag>
      </template>
      <template slot="operation" slot-scope="{record}">
        <action-view @click="view(record)"></action-view>
        <action-delete v-if="!product.allDevice" @click="remove(record)"></action-delete>
      </template>

    </common-table>

    <vendor-device-add ref="vendorDeviceAdd" @success="getList"></vendor-device-add>

  </a-card>

</template>
<script>

  import VendorDeviceAdd from "./VendorDeviceAdd";
  export default {
    components:{VendorDeviceAdd},
    data () {
      return {
        queryParams:{
          vendorId:this.$route.params.vendorId,
          productKey:this.$route.query.productKey
        },
        vendor:{},
        product:{}

      }
    },
    created () {

      // this.queryParams.vendorId = this.$route.params.vendorId

      this.$get('vendor/'+this.queryParams.vendorId).then((r) => {
        let data = r.data
        this.vendor = data
      })

      this.$get('product/getInfo',{
        vendorId:this.queryParams.vendorId,
        productKey:this.$route.query.productKey
      }).then((r) => {
        let data = r.data
        this.product = data
      })

    },
    computed: {

      columns () {
        return [
          {
            title: '产品',
            dataIndex: 'productName',
          },
          {
            title: '设备ID',
            dataIndex: 'iotId'
          },
          {
            title: '设备MAC',
            dataIndex: 'name'
          },
          // {
          //   title: '激活时间',
          //   dataIndex: 'activeTime'
          // },
          {
            title: '在线状态',
            dataIndex: 'status',
            scopedSlots: {customRender: 'status'},
          },
          {
            title: '操作',
            dataIndex: 'operation',
            scopedSlots: {customRender: 'operation'},
          }
        ]
      }
    },
    methods: {

      view (record) {
        this.$router.push(`/device/info/${record.iotId}`)
      },
      remove(record){
        let that = this
        this.$confirm({
          title: '是否删除该设备授权?',
          content: '当您点击确定按钮后，经销商将不再拥有该设备的控制权',
          centered: true,
          onOk () {
            that.$delete('vendor/' + that.$route.params.vendorId + '/device/' + record.iotId).then(() => {
              that.$message.success('删除成功')
              that.getList()
            })
          },

        })
      },

      add () {
        this.$refs.vendorDeviceAdd.show({
          productName:this.product.name,
          ...this.queryParams
        })
        // let vendorId = this.$route.params.vendorId
        // this.$router.push(`/vendor/${vendorId}/deviceadd`)
      },

      getList () {
        this.$refs.table.getData()

        this.$get('product/getInfo',{
          vendorId:this.queryParams.vendorId,
          productKey:this.$route.query.productKey
        }).then((r) => {
          let data = r.data
          this.product = data
        })
      }
    }
  }
</script>
<style lang="less" scoped>

  .device-message {
    height: 92px;
    width: 100%;
    position: relative;
    margin-bottom: 12px;
  }

  .device-message-content {
    height: 92px;
    width: 23.889%;
    box-shadow: 0 0 1px 0px rgb(206, 206, 206);
    float: left;
    margin-left: 1.48%;
    background-color: #fff;
    position: relative;
  }

  .device-message-content:first-child {
    margin-left: 0px;
  }

  .device-message-num {
    float: right;
    font-size: 28px;
    margin-right: 16px;
    line-height: 92px;
  }

  .device-message-img {
    float: left;
    font-size: 12px;
    color: #333;
    height: 72px;
    width: 80px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    margin-left: 24px;
  }

  .device-message-img > div {
    margin-bottom: 14px;
  }
</style>
